import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Gallery from '../components/Gallery';
import * as style from '../assets/style/pages/realizations-page.module.scss';

const RealizationsPage = ({ location, data }) => {
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Elementy aranżacji wnętrz', url: '/elementy-aranzacji-wnetrz' }
  ];
  const interiorDesignElements = data.interiorDesignElements.edges.map(({ node }) => ({
    id: node.image.value.id,
    image: node.image.value,
    source: node.image.value.url,
    title: node.title.value,
  }));

  return (
    <Layout>
      <Seo title="Realizacje">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [${
              crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.realizations}>
        <div className="container">
          <Gallery images={interiorDesignElements} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    interiorDesignElements: allCockpitInteriorDesignElements {
      edges {
        node {
          image {
            value {
              id
              url
              childImageSharp {
                gatsbyImageData(
                  width: 350,
                  quality: 100,
                  placeholder: BLURRED,
                  formats: WEBP
                )
              }
            }
          }
          title {
            value
          }
        }
      }
    }
  }
`;

export default RealizationsPage;
